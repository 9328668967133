import styled from 'styled-components';

import { Linkedin, Github }  from '@styled-icons/boxicons-logos';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  min-width: 290px;
`;

export const Content = styled.div`
  display: flex;
  flex: 6;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 5rem;
  @media(max-width: 600px) {
    padding: 1rem;
  }
`;

export const Title = styled.h1`
  font-size: 3.1rem;
  @media(max-width: 600px) {
    font-size: 2rem;
  }
  @media(max-width: 329px) {
    font-size: 1.7rem;
  }
`;

export const Message = styled.p`
  padding-top: 50px;

  font-size: 20px;
  @media(max-width: 600px) {
    font-size: 1rem;
  }
  @media(max-width: 425px) {
    text-align: justify
  }
`;

export const MailTo = styled.a.attrs({
  href: 'mailto:jeffersoon.ramos@gmail.com'
})`
  text-decoration: none;
  color: var(--red);
  
  :hover {
    color: var(--text-default);
    text-decoration: none;
  }
`;

export const Footer = styled.footer`
  display: flex;
  flex: 1;
  justify-content: space-around;
  align-items: center;
  
  width: 100%;

  background-color: var(--black);
`;

export const SocialLinks = styled.div``;

export const SocialLink = styled.a`
  text-decoration: none;
  color: var(--shape);

  :hover {
    text-decoration: none;
  }
`;

export const GithubIcon = styled(Github).attrs({
  size: 30
  
})`
  margin-right: 25px;
`;

export const LinkedinIcon = styled(Linkedin).attrs({
  size: 30
})``;

export const Signature = styled.span`
  font-size: 14px;
  
  color: var(--shape);
`;