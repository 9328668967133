
import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  :root {        
    --red: #e52e4d;
    --green: #33cc95;
    --blue: #5429cc;
    --black: #000000;
    
    --blue-light: #6933ff;
    
    --text-default: #333;
    --text-title: #363f5f;
    --text-body: #969cb3;
    
    --background: #f0f2f5;
    --shape: #ffffff;
  }
    
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html, body {
    height: 100%;
    width: 100%;
    font-size: 16px;
  }

  
  body {
    background: var(--background);
    -webkit-font-smoothing: antialiased;
  }
  
  body, input, textarea, button, span {
    font-family: 'Helvetica', sans-serif;
    font-weight: 400;
  }
  
  h1, h2, h3, h4, h5, h6 , strong {
    font-weight: 600;
  }    
`;