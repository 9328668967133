import React from 'react';
import { Maintenance } from './screens/Maintenance';

import { GlobalStyle } from './styles/global';

export function App() {
  return (
    <>
      <Maintenance />
      <GlobalStyle />
   </>
  );
}
