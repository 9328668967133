
import {
  Container,
  Content,
  Title,
  Message,
  MailTo,
  Footer,
  SocialLinks,
  SocialLink,
  LinkedinIcon,
  GithubIcon,
  Signature
} from './styles';

export function Maintenance() {
  return (
    <Container>
      <Content>
          <Title>
            Site em constru&ccedil;&atilde;o
          </Title>
          <Message>
            Estamos realizando a construção do site no momento. 
            Se desejar, você pode <MailTo>entrar em contato</MailTo>.
          </Message>
      </Content>
      <Footer>
        <SocialLinks>
          <SocialLink href="https://github.com/jeffersonramos">          
            <GithubIcon />
          </SocialLink>
          <SocialLink href="https://www.linkedin.com/in/jeffersoonramos/">
            <LinkedinIcon />
          </SocialLink>
        </SocialLinks>
        <Signature>
          Jefferson · 2021
        </Signature>
      </Footer>
    </Container>
  );
}